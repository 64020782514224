[color-scheme="dark"] {
	.self-knowledge-wrapper {
		.content-container {
			&.key-0 {
				.step {
					&.initial {
						button {
							color: var(--c1-dark);
						}
					}
				}
			}
		}
	}
}

.self-knowledge-wrapper {
	width: 100%;
	max-width: 100%;
	min-width: 60%;
	padding: 1.75rem;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	gap: 1.5rem;
	border-radius: 0.75rem;
	background-color: var(--c3);

	input.form-control {
		background: var(--c5);
		color: var(--white);
		border-radius: 1rem;
		border-color: var(--c5);
		font-size: 1.2rem;
	}

	.stages-container {
		width: 13rem;
		height: fit-content;
		position: sticky;
		top: 1.75rem;
		display: flex;
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
		gap: 1.75rem;
		border-radius: 0.75rem;

		.stage-indicator {
			opacity: 0.25;
			width: 100%;
			min-height: 10rem;
			padding: 1.75rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.75rem;
			border: none;
			font-family: inherit;
			font-size: 1.125rem;
			font-weight: 700;
			color: var(--white);
			background-color: #5d831b1a;
			transition-property: color, background-color;
			transition-duration: 0.15s;
			transition-timing-function: ease-in;

			&:focus,
			&.is-active {
				opacity: 1;
				background-color: var(--c1);
			}

			&:not(:focus):not(.is-active) {
				&:hover {
					color: var(--white);
				}
			}

			&.key-0 {
				background-color: var(--c4);
			}

			&.key-1 {
				background-color: var(--c5);
			}

			&.key-2 {
				background-color: var(--c6);
			}

			&.key-3 {
				background-color: var(--c7);
			}
		}
	}

	.content-container {
		display: block;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		border-radius: 0.75rem;
		background-color: var(--c1);

		.content {
			height: 100%;

			.step {
				height: 100%;
				display: none;

				&.is-active {
					display: flex;
				}
			}
		}

		&.key-0 {
			background-color: var(--c4);

			.step {
				&.initial {
					.title,
					.text {
						color: var(--white);
					}
				}
			}
		}

		&.key-1 {
			background-color: var(--c5);
		}

		&.key-2 {
			background-color: var(--c6);
		}

		&.key-3 {
			background-color: var(--c7);
		}
	}
}

@media (max-width: 60rem) {
	.self-knowledge-wrapper {
		flex-flow: wrap column;

		.stages-container {
			width: 100%;
			padding: 0 0 1rem 0;
			position: relative;
			top: unset;
			overflow: auto hidden;
			flex-flow: nowrap row;
			align-items: stretch;
			justify-content: flex-start;
			gap: 1rem;

			.stage-indicator {
				padding: 1.75rem 0.5rem;
				font-size: 0.85em;
				min-width: 10rem;
				min-height: fit-content;
			}
		}
	}
}
