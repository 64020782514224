.homepage {
	--plyr-color-main: var(--c2);

	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: center;
	justify-content: flex-start;
	gap: 2rem;
	color: var(--text);

	.row,
	.col {
		margin: 0 !important;
		padding: 0 !important;
	}

	.card {
		width: 100%;
		margin: 0 !important;
		padding: 1rem 1rem 0 0;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-end;
		justify-content: space-between;
		gap: 2.5rem;
		border: none;
		border-radius: 0.75rem;
		background-color: var(--c3);
		box-shadow: none;

		.image {
			width: fit-content;
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		.text-content {
			padding: 0 1.5rem;
			width: 100%;
			display: flex;
			flex-flow: wrap column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 0.5rem;
		}

		.actions {
			margin-bottom: 1rem;
			display: flex;
			flex-flow: wrap row;
			justify-content: flex-end;
			gap: 1rem;

			.btn-view {
				font-size: 0.875rem;
				color: #adcd56 !important;
				background-color: #0000 !important;
			}
		}

		.title,
		.text {
			width: 90%;
			margin: 0 !important;
		}

		.title-pontuacao-home {
			margin: 0 !important;
			color: var(--text);
		}

		.title {
			color: var(--text);
		}

		.text {
			display: flex;
			flex-flow: wrap column;
			gap: 1rem;
			line-height: 1.5;
			text-align: left;

			* {
				margin: 0 !important;
				line-height: inherit;
				font-size: inherit;
			}
		}

		button,
		.btn {
			width: fit-content;
			margin: 0 !important;
			border-color: var(--c2) !important;
			font-size: 0.875rem;
			color: var(--c1-dark) !important;
			background-color: var(--c2) !important;
			box-shadow: none !important;

			&.btn-video-modal-slide {
				display: flex;
				align-items: center;
				gap: 1rem;
				text-transform: uppercase;
				margin: 0 auto !important;
				border: none !important;
				color: var(--text) !important;
				background-color: var(--c1) !important;
			}

			&.btn-video-modal {
				border-color: var(--c2) !important;
				color: var(--c2) !important;
				background-color: transparent !important;

				&:focus,
				&:hover {
					color: var(--text) !important;
					background-color: var(--c1) !important;
				}
			}

			&:focus,
			&:hover {
				border-color: var(--c2) !important;
				color: var(--text) !important;
				background-color: var(--c2) !important;
				box-shadow: none !important;
			}
		}

		.swiper {
			width: 100%;
			padding: 1rem;
			display: flex;
			position: relative;

			&-wrapper {
				align-items: center;
			}

			&-slide {
				padding: 1rem;
			}
		}

		.video-container,
		.chart-container {
			width: 100%;
			display: flex;
			flex-flow: wrap column;
			align-items: center;
			justify-content: center;
			gap: 1rem;
		}

		.video-container {
			.player {
				width: 90%;
				position: relative;
				overflow: hidden;
				border-radius: 0.75rem;
			}
		}

		.chart-container {
			.chart-header {
				width: 100%;
				display: flex;
				flex-flow: wrap column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 1em;

				.title,
				.title-pontuacao-home {
					color: var(--c2);
				}
			}

			.chart-notes {
				width: 100%;
				display: flex;
				gap: 2rem;
				justify-content: center;
				align-items: center;

				.title,
				.title-pontuacao-home {
					span {
						color: var(--c2);

						&.note {
							font-family: "Roboto", sans-serif !important;
							color: var(--text);
						}
					}
				}
			}

			.chart {
				width: 100%;
				height: auto !important;
			}

			.labels {
				width: 100%;
				padding: 0.5rem;
				display: flex;
				flex-flow: wrap row;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;

				.label {
					margin: 0;
					padding: 0.5rem;
					flex-basis: 0;
					flex-grow: 1;
					flex-shrink: 1;
					display: flex;
					flex-flow: wrap column;
					align-items: center;
					justify-content: center;
					gap: 1rem;
					border-radius: var(--radius-medium);
					font-size: 1rem;
					font-weight: 800;

					&:nth-child(1) {
						color: var(--white);
						background-color: var(--c8);
					}

					&:nth-child(2) {
						color: var(--white);
						background-color: var(--c9);
					}

					&:nth-child(3) {
						color: var(--white);
						background-color: var(--c10);
					}

					&:nth-child(4) {
						color: var(--white);
						background-color: var(--c12);
					}

					&:nth-child(5) {
						color: var(--white);
						background-color: var(--c11);
					}
				}
			}
		}

		&.welcome {
			background-color: transparent;

			.row {
				align-items: stretch;
				gap: 0 !important;
			}

			&::before {
				content: "";
				width: 100%;
				height: calc(100% - 3rem);
				position: absolute;
				left: 0;
				bottom: 0;
				border-radius: 0.75rem;
				background-color: var(--c3);
			}
		}

		&.profile-card {
			min-width: calc(var(--card-padding) + 12rem);
			max-height: 100%;
			padding-top: var(--card-padding);
			position: sticky;
			top: 2.625rem;
			flex-flow: wrap column;
			align-items: center;
			justify-content: space-between;
			gap: 2.625rem;

			.pic {
				width: 12.5rem;
				height: 12.5rem;
				margin: 0;
				padding: 0.3125rem;
				position: relative;
				overflow: hidden;
				display: flex;
				border-radius: 50%;
				border: 0.125rem solid var(--c1);
				background-color: var(--c2);

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					border-radius: 100%;
				}
			}

			.name {
				width: 100%;
				margin: 0;
				text-align: center;
				font-size: 1.5rem;
				color: var(--c2);
			}

			.info,
			.configs {
				width: 100%;
				display: grid;
				grid-auto-flow: row;
				align-items: center;
				justify-content: center;
				gap: 2rem;
				border-top: 0.125rem solid var(--white);

				.item,
				a {
					width: 100%;
					margin: 0;
					padding: 0;
					display: inline-flex;
					gap: 0.5em;
					font-size: 1rem;

					i {
						color: var(--c2);
					}
				}

				.item {
					align-items: flex-start;
					justify-content: flex-start;

					.label {
						display: inline-flex;
						gap: 0.5em;
					}
				}

				a {
					align-items: center;
					justify-content: center;
				}
			}

			.info {
				padding: 2.625rem var(--card-padding) 0 var(--card-padding);
			}

			.configs {
				a {
					padding: 2.625rem var(--card-padding);
					cursor: pointer;

					&:focus,
					&:hover {
						color: var(--c1-dark);
					}
				}
			}
		}
	}
}

@media (max-width: 86rem) {
	.homepage {
		.card {
			.chart-container {
				.chart-notes {
					.title,
					.title-pontuacao-home {
						font-size: 1.375rem;
					}
				}
			}
		}
	}
}

@media (max-width: 64rem) {
	.homepage {
		.card {
			&.welcome {
				.row {
					width: 100%;
					gap: 1.5625rem !important;

					.title,
					.title-pontuacao-home {
						width: 100%;
					}

					.text {
						width: 100%;
					}
				}
			}

			.actions {
				position: relative;
				bottom: 0;
			}

			.swiper {
				padding: 0;

				.swiper-slide {
					&:first-child {
						padding: 0;
					}
				}
			}

			&.graph {
				padding: 0.5rem;
			}
		}
	}
}

@media (max-width: 56.25rem) {
	.homepage {
		.card {
			.chart-container {
				.chart-notes {
					flex-flow: wrap column;
					gap: 1rem;
					align-items: start;

					.title,
					.title-pontuacao-home {
						font-size: 1.125rem;
					}
				}
			}
		}
	}
}
