.step {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	border-radius: 0.75rem;
	color: var(--white);

	.title,
	.text {
		width: 100%;
		margin: 0;
		text-align: left;
		color: var(--white);

		* {
			font-size: inherit;
		}
	}

	.title {
		display: flex;
		flex-flow: wrap column;
		gap: 1em;
	}

	.text {
		display: grid;
		grid-auto-flow: row;
		gap: 1rem;

		* {
			margin: 0;
		}

		p {
			i {
				margin: 0 0.5rem 0 0;
			}
		}
	}

	.icon {
		width: fit-content;
		height: fit-content;

		i {
			font-size: 1.25rem;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	.title-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.25rem;

		.title {
			width: unset;
			display: block;
			flex-basis: 0;
			flex-grow: 1;
			flex-shrink: 1;
			text-align: left;
		}
	}

	button {
		width: fit-content;
		margin: 0;
		border-color: var(--white);
		color: var(--c1-dark);
		background-color: var(--white);
		font-size: 1.125rem;

		&.step-backward {
			margin: 0 !important;
		}

		&.step-forward {
			margin: 1rem 0 0 auto !important;
		}

		&:focus,
		&:active,
		&:hover {
			border-color: var(--white) !important;
			color: var(--c1-dark) !important;
			background-color: var(--white) !important;
		}
	}

	.badge {
		padding: 0.5rem 1rem;
		font-size: 1.125rem;
		background-color: var(--c1-dark);

		&.key-0 {
			background-color: var(--c7) !important;
		}

		&.key-1 {
			background-color: var(--c6) !important;
		}

		&.key-2 {
			background-color: var(--c5) !important;
		}

		&.key-3 {
			background-color: var(--c4) !important;
		}
	}

	label {
		margin: 0;
	}

	.checkbox-group {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 2rem;

		.checkbox-row {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			gap: 1rem;
		}

		.checkbox-label {
			font-size: 1.125rem;
			font-weight: 700;
		}
	}

	.radio-button-var-group {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 2rem;

		.radio-button-var-row {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 1rem;
		}

		.radio-button-var-label {
			font-size: 1.125rem;
			font-weight: 400;
		}
	}

	.radio-group {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 2rem;

		.radio-row {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 1rem;
		}

		.radio-label {
			font-size: 1.125rem;
			font-weight: 400;
		}
	}

	&.initial,
	&.final {
		align-items: stretch;
		justify-content: center;

		.text-content {
			width: 40%;
			padding: 2rem 3.75rem;
			display: flex;
			flex-flow: wrap column;
			align-items: center;
			justify-content: center;
			gap: 1rem;

			.icon {
				width: 100%;
				display: flex;
				justify-content: flex-start;

				svg {
					width: 4.25rem;
					height: 4.25rem;
				}
			}

			button {
				margin: 1rem 0 0 auto;
			}

			a {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
		}

		.image-bg {
			width: 60%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&.final {
		color: #43433b;
		background-color: #aecd58;

		.title,
		.text {
			color: #43433b;
		}
	}

	&.secondary {
		width: 100%;
		padding: 2rem 3.75rem;
		flex-flow: wrap column;
		gap: 4rem;
	}

	&.error {
		padding: 2rem 3.75rem;
		flex-flow: wrap column;
	}
}

@media (max-width: 90rem) {
	.step {
		.checkbox-group {
			.checkbox-row {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
	}
}

@media (max-width: 64rem) {
	.step {
		gap: 2rem !important;

		.checkbox-group {
			.checkbox-row {
				grid-template-columns: 1fr 1fr;
			}
		}

		.radio-button-var-group {
			.radio-button-var-row {
				grid-template-columns: 1fr 1fr;
			}

			.radio-button-var-label {
				font-size: 1rem;
			}
		}

		&.final {
			flex-flow: wrap column-reverse;

			.text-content {
				width: 100%;
				padding: 2rem !important;
				gap: 1rem !important;

				.icon {
					justify-content: center;
				}
			}

			.image-bg {
				display: none;
			}
		}

		.title {
			&.p {
				font-size: 1.5rem;
			}
		}

		.text {
			font-size: 1.25rem;
		}

		.radio-group {
			.radio-row {
				grid-template-columns: 1fr;

				.radio-wrapper {
					flex-flow: nowrap row;
				}
			}

			.radio-label {
				font-size: 1rem;
			}
		}
	}
}

@media (max-width: 60rem) {
	.step {
		.title-wrapper {
			gap: 1rem;

			button {
				margin: 0 auto 0 0 !important;
			}

			.title {
				font-size: 1.5rem;
			}

			.badge {
				display: none;
			}
		}

		.checkbox-group {
			.checkbox-row {
				grid-template-columns: 1fr;
				width: 100%;
				white-space: pre;
			}
		}

		.radio-button-var-group {
			.radio-button-var-row {
				grid-template-columns: 1fr;
				width: 100%;
				white-space: pre;
			}
		}

		&.initial,
		&.secondary {
			width: 100%;
		}

		&.initial {
			flex-flow: wrap column-reverse;

			.image-bg {
				width: 100%;
				height: 35vh;
			}

			.text-content {
				width: 100%;
				padding: 2rem;
			}
		}

		&.secondary {
			padding: 2rem;
		}
	}
}

@media (max-width: 27rem) {
	.step {
		.checkbox-group {
			.checkbox-row {
				width: 100%;
				white-space: pre;
			}
		}

		.radio-button-var-group {
			.radio-button-var-row {
				width: 100%;
				white-space: pre;
			}
		}

		.radio-group {
			.radio-row {
				.radio-wrapper {
					span {
						font-size: 0.85rem;
					}
				}
			}
		}
	}
}
