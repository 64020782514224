[color-scheme="light"] {
	.header {
		.title {
			color: var(--c2);
		}
	}
}

.is-loading-home {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9;
	background: var(--c3);
	display: flex;
	align-items: center;
	justify-content: center;
}

.painel-master-page {
	.row,
	.col-lg-6 {
		margin: 0 !important;
		padding: 0 !important;
	}
}
