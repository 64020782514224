.EditProfile {
	float: left;
	width: 100%;
	margin-bottom: 1rem;

	.profile {
		width: 30%;
		float: left;
		padding: 0;
	}

	.content {
		width: 70%;
		float: left;
		padding-right: 0;
	}

	.card {
		width: 100%;
		margin: 0 !important;
		padding: 1rem;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: wrap column;
		align-items: flex-end;
		justify-content: space-between;
		gap: 2.5rem;
		border-radius: 0.75rem;
		background-color: var(--c3);
		box-shadow: none;

		.card-body {
			padding: 1rem;
			width: 100%;

			.tabs {
				padding: 0 !important;
			}
		}

		.image {
			width: fit-content;
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		&.profile-card {
			min-width: calc(1.25rem + 12rem);
			max-height: 100%;
			padding-top: 1.25rem;
			position: sticky;
			top: 2.625rem;
			flex-flow: wrap column;
			align-items: center;
			justify-content: space-between;
			gap: 2.625rem;

			.pic {
				width: 12.5rem;
				height: 12.5rem;
				margin: 0;
				padding: 0.3125rem;
				position: relative;
				overflow: hidden;
				display: flex;
				border-radius: 50%;
				border: 0.125rem solid var(--c1);
				background-color: var(--c2);

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					border-radius: 100%;
				}
			}

			.name {
				width: 100%;
				margin: 0;
				text-align: center;
				font-size: 1.5rem;
				color: var(--c2);
			}

			.label {
				span {
					color: var(--text);
				}
			}

			.answer {
				color: var(--text);
			}

			.info,
			.configs {
				width: 100%;
				display: grid;
				grid-auto-flow: row;
				align-items: center;
				justify-content: center;
				gap: 2rem;
				border-top: 0.125rem solid var(--white);

				.item,
				a {
					width: 100%;
					margin: 0;
					padding: 0;
					display: inline-flex;
					gap: 0.5em;
					font-size: 1rem;

					i {
						color: var(--c2);
					}
				}

				.item {
					align-items: flex-start;
					justify-content: flex-start;

					.label {
						display: inline-flex;
						gap: 0.5em;
					}
				}

				a {
					align-items: center;
					justify-content: center;
				}
			}

			.info {
				padding: 2.625rem 1rem 0 1rem;

				.solicitation {
					color: var(--c2);
					text-decoration: none;
				}
				.solicitation:hover {
					color: var(--c4-dark);
					text-decoration: underline;
				}
			}

			.configs {
				a {
					padding: 2.625rem var(--card-padding);
					cursor: pointer;

					&:focus,
					&:hover {
						color: var(--c1-dark);
					}
				}
			}
		}
	}

	.form-default {
		width: 100%;
		padding: 0;
		border-radius: 1.625rem;

		.tabs {
			padding: 0 3.25rem;

			.row {
				align-items: unset;
				gap: unset;
			}
		}
	}

	.react-datepicker__navigation-icon {
		top: 0.625rem;
	}

	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow,
	.react-datepicker__month-year-read-view--down-arrow,
	.react-datepicker__navigation-icon::before {
		border-color: #000;
	}
}

.Footer {
	float: left;
	width: 100%;
}

@media (max-width: 60rem) {
	.EditProfile {
		.profile {
			width: 100%;
		}

		.content {
			width: 100%;
			padding: 0;
			margin-top: 1rem;
		}
	}
}
