@media (max-width: 48rem) {
	.profile {
		.card {
			&.profile-card {
				.info {
					padding: 1rem 0rem !important;
				}
			}
		}
	}
}
