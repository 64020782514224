.input-text-row {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;

	label {
		margin: 0;
		font-size: 1.125rem;
		font-weight: 400;
	}

	input {
		max-width: 60%;
	}
}

@media (max-width: 56.25rem) {
	.input-text-row {
		label {
			font-size: 1rem;
		}

		input {
			width: 100%;
			max-width: 100%;
		}
	}
}
