.header {
	.icon {
		width: 2.5rem !important;
		height: 2.5rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.5rem;
		color: var(--c2);
		background-color: var(--c1-dark);
		transition-property: color, background-color;
		transition-duration: 0.15s;
		transition-timing-function: ease-in;
		color: var(--text);
		margin-right: 1rem;

		i {
			font-size: 1.25rem;
		}
	}

	.title {
		color: var(--heading-title);
		display: flex;
		align-items: center;

		svg,
		img {
			max-height: 4rem;
			margin-right: 1rem;
			position: relative;
			font-size: 1.125rem;
			object-fit: cover;
			object-position: center;
		}
	}

	.text {
		margin: 0 !important;
		font-size: 0.875rem;
		color: var(--text);
	}
}

@media (max-width: 64rem) {
	.header {
		.col-lg-6 {
			padding: 0 !important;
		}

		.title {
			flex-flow: wrap column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 1rem;
			font-size: 1.5rem;
		}
	}
}
