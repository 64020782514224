.login {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-flow: wrap row;
	align-items: stretch;
	justify-content: flex-start;
	gap: 0;
	background-color: var(--c1-dark);

	.col {
		height: 100%;
		display: grid;
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		overflow: hidden auto;

		&.form-content {
			background-color: var(--c1-dark);

			.welcome-message {
				h1 {
					color: var(--c2-dark);
				}
			}

			.tabs {
				.links-group {
					.tablink {
						opacity: 1;
					}
				}
			}

			form {
				.fieldset {
					&.toggle {
						label {
							&.toggle {
								.toggle-display {
									background-color: var(--white) !important;

									.toggle-icon {
										&.not-checked {
											color: var(--c8-dark);
										}
									}

									&::before {
										background-color: var(--c1-dark);
									}
								}
							}
						}
					}

					input,
					.input {
						--border-color: var(--white) !important;
						--color: var(--text) !important;
						--background-color: var(--c1) !important;
						font-weight: 400;
						font-size: 1rem !important;

						&:focus {
							border-color: var(--border-color);
							background-color: var(--c3) !important;
						}

						&:-webkit-autofill,
						&:-webkit-autofill:hover,
						&:-webkit-autofill:focus,
						&:-webkit-autofill:active {
							color: var(--color) !important;
							-webkit-text-fill-color: var(--color) !important;
							background-color: var(--c1) !important;
							box-shadow: 0 0 0 1.875em var(--c1) inset !important;
						}

						&:disabled {
							opacity: 0.3;
						}

						&.invalid {
							border: 0.125rem solid var(--error);
						}
					}

					.disabled {
						opacity: 0.8 !important;

						.css-zvjqnp-Control {
							.css-319lph-ValueContainer {
								.css-fhh3xu-singleValue,
								.css-oannl4-placeholder {
									--color: var(--c1-dark) !important;
								}
							}
						}
					}
				}
			}
		}

		&.visual-content {
			align-items: center;
			justify-content: center;

			.wrapper {
				width: 100%;
				padding: 1rem 0 1rem 6.25rem;
				position: relative;
				display: flex;
				flex-flow: wrap column;
				gap: 3.125rem;
				line-height: 1.2;
				text-align: left;
				font-size: 2rem;
				font-weight: 400;

				&::before {
					content: "";
					width: 0.3125rem;
					height: 100%;
					position: absolute;
					transform: translate(-50%, -50%);
					top: 50%;
					left: 3.125rem;
					border-radius: 0.3125rem;
					background-color: var(--c2);
				}
			}
		}
	}

	.image {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			object-position: center;
		}

		&.cover {
			height: 100%;

			img {
				object-fit: cover;
			}
		}
	}

	.title {
		font-size: 1.25rem !important;
		color: var(--white);

		&.p {
			font-size: 1.875rem !important;
		}

		&.s {
			font-size: 1.25rem !important;
		}

		&.t {
			font-size: 1.125rem !important;
		}
	}

	.text {
		width: 100%;
		line-height: 1.75;
		text-align: left;
		font-size: 1.25rem;
	}
}

@media (max-width: 85.375rem) {
	.login {
		.col {
			&.form-default {
				.tabs {
					.tabcontent {
						form {
							.fieldset {
								input[type="text"],
								input[type="email"],
								input[type="password"] {
									max-width: 100%;
								}
							}

							button {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 64rem) {
	.login {
		height: 100%;
		flex-flow: wrap column-reverse;
		align-items: center;
		justify-content: center;

		.col {
			&.form-default,
			&.visual-content {
				width: 100%;
				height: fit-content;
				padding: 4rem 10%;
				flex: none;
				display: flex;
			}

			&.form-default {
				.brand {
					padding: 0 0 2rem 0;
				}

				.welcome-message,
				.tabs {
					padding: 0;
				}

				.welcome-message {
					h1 {
						font-size: 1.65rem;
					}

					h2 {
						font-size: 1.15rem;
					}
				}

				.tabs {
					.links-group {
						.tablink {
							font-size: 1.1rem;
						}
					}
				}
			}

			&.visual-content {
				.wrapper {
					padding: 0.5rem 0 0.5rem 1.5625rem;
					font-size: 1.5rem;

					&::before {
						left: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 48rem) {
	.login {
		height: max-content;

		.col {
			&.form-default {
				height: 100%;
				min-height: 100vh;
			}
		}
	}
}
