.input-file-row {
	width: 100%;
	display: flex;
	flex-flow: wrap column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;

	label {
		margin: 0;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.btn-image {
		background-color: #76ab1c !important;
		border-color: #76ab1c !important;
		color: var(--white) !important;
		border-radius: 1.875rem;
		padding: 0.7rem 2rem;
		font-size: 1.125rem !important;
		line-height: 1.5rem !important;
		font-weight: 400 !important;

		&:hover,
		&:active,
		&:visited {
			background-color: #76ab1c !important;
			border-color: #76ab1c !important;
			color: var(--white) !important;
		}
	}

	input {
		display: none;
	}
}

@media (max-width: 56.25rem) {
	.input-file-row {
		label {
			font-size: 1rem;
		}
	}
}
